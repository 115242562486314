<template>
    <div class="container-fluid d-flex justify-content-end">
        <div class="d-flex justify-content-center col-2">
          <a href="" class="link-success" @click="sortBobles($event, 'sortBest')"><i class="fa-solid fa-arrow-up-wide-short"></i></a>
          <a href="" class="link-danger" @click="sortBobles($event, 'sortWorst')"><i class="fa-solid fa-arrow-down-short-wide"></i></a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Sort',
  methods: {
      sortBobles(event, name) {
        event.preventDefault();
        this.$emit(name);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
