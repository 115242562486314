<template>
    <div class="container d-flex flex-column">
        <h3 class="text-center">{{symbol}}</h3>    
        <div class="progress">
          <div :class="getColor()" class="progress-bar" :style="{'width': width + '%'}"></div>
        </div>
      </div>
</template>

<script>
    export default {
        name: 'ProgressBar',
        props: {
          symbol: String,
          width: String
        },
        methods: {
          getColor() {
            if(this.width > 75) return('high');
            else if(this.width > 50) return('high-medium');
            else if(this.width > 25) return('medium');
            else if(this.width > 5) return('low-medium');
            else return('low');
          }
        }
    }
</script>

<style scoped>

@media (max-width: 768px) {
  .container {
    width: 90% !important;
  }
}

.container {
  margin-top: 15px !important;
  margin-bottom: 70px !important;
}

.low {
  background-color: #f63a0f !important;
}

.low-medium {
  background-color: #f27011 !important;
}

.medium {
  background-color: #f2b01e !important;
}

.high-medium {
  background-color: #f2d31b !important;
}

.high {
  background-color: #86e01e !important;
}

.progress {
  width: 100%;
  height: 100%;
  padding: 4px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 16px;
  border-radius: 4px;
	background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
}

</style>