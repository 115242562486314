<template>
    <div class="header d-flex align-items-center">
        <div class="container-fluid">
            <div class="row">
                <div class="d-flex flex-column align-items-center">
                    <h1 class="text-center">{{title}}</h1>
                    <h3 class="text-center mt-3">{{text}}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String,
    text: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .header {
        height: 200px;
    }

    h1 {
        font-size: 30px;
        font-weight: 700;
    }

    h3 {
        font-size: 20px;
        letter-spacing: 2px;
    }

    @media (max-width: 576px) {
        h1 {
            font-size: 25px;
        }

        h3 {
            font-size: 15px;
            text-align: center;
        }
    }
</style>
