<template>
    <div class="menu">
        <div class="container h-100 pt-2">
          <div class="row h-100 d-flex align-items-center">
              <div class="customBrand col-2 d-flex align-items-center">
                  <img src="https://boblles.com/assets/images/logo-removebg-preview-128x128.png">
                  <a href="#">BOBLLES</a>
              </div>
              <div class="customNav d-flex justify-content-end col-10">
                <!-- <button class="navbar-toggler bg-light d-sm-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa fa-bars"></i>
                </button> -->
                  <div class="links d-sm-flex align-items-center d-none">
                      <a href="#">LISTING</a>
                      <a href="#footer">FOLLOW</a>
                      <a href="/api">API</a>
                  </div>
                  <div class="buttons d-sm-block d-none">
                      <a href="#how-it-works" class="btn btn-warning">HOW IT WORKS</a>
                  </div>
              </div>
              <!-- <div class="collapse" id="navbarToggleExternalContent">
                <div class="d-flex align-items-center flex-column">
                    <a href="#">LISTING</a>
                    <a class="mb-2" href="#">CONTACT</a>
                </div>
            </div> -->
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .menu {
        height: 70px;
        /* background-color: #282828; */
    }
    
    a {
        text-decoration: none;
    }

    a:hover {
        color: gray;
    }

    .customBrand a {
        font-size: x-large;
        font-weight: 800;
        letter-spacing: 2px;
        margin-left: 10px;
    }

    .customBrand img {
        max-width: 50px;
    }

    .links a {
        padding-left: 10px;
        letter-spacing: 4px;
    }
    
    .buttons {
        margin-left: 10px;
    }

    .buttons .btn {
        color: black;
    }

</style>
