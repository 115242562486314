<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" 
        :is-full-page="fullPage"
        loader="dots"
        :width="150"
        ></loading>
    </div>
</template>

<script>
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    
    export default {
        data() {
            return {
                fullPage: true
            }
        },
        props: {
            isLoading: Boolean
        },
        components: {
            Loading
        },
        methods: {
            doAjax() {
                this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                  this.isLoading = false
                },5000)
            },
            onCancel() {
              console.log('User cancelled the loader.')
            }
        }
    }
</script>