<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="d-flex align-items-center">
      <img v-if="boble.newBoble" id="newTitle" :src="boble.symbol == 'HMMM' ? 'https://img.icons8.com/emoji/344/fire.png' : 'https://img.icons8.com/emoji/48/000000/new-button-emoji.png'" height="24">
      <h1 class="mb-0">{{boble.symbol}}</h1>
    </div>
    <div :class="boble.percent >= 60 ? 'green' : boble.percent >= 40 ? 'orange' : 'red'" class="box__ball">
      <a :href="boble.buyLink">
        <div class="progress" @mouseenter="setActive()" @mouseleave="setActive()">
          <div class="inner">
            <div class="percent d-flex justify-content-center align-items-center"><span>{{boble.active ? 'BUY' : boble.percent + '%'}}</span></div>
            <div class="water" :style="{top: 100 - boble.percent+'%'}"></div>
            <div class="glare"></div>
          </div>
        </div>
      </a>
      <div v-if="!boble.voted" class="mt-4 d-flex justify-content-center">
        <div class="d-flex justify-content-between btn-vote">
          <button class="btn btnUp btn-text" @click="setVote(null, 'up')">UP</button>
          <button class="btn btnDown btn-text ms-2 ms-md-4" @click="setVote(null, 'down')">DOWN</button>
        </div>
      </div>
      <div v-if="boble.voted">
        <div class="mt-4 d-flex justify-content-center">
          <span class="btn-text">{{boble.upVotes}}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
              fill="#029502" class="bi bi-arrow-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
            </svg></span>
          <span class="btn-text ms-2 ms-md-4">{{boble.downVotes}}<svg xmlns="http://www.w3.org/2000/svg" width="16"
              height="16" fill="#f07c3e" class="bi bi-arrow-down" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
            </svg></span>
        </div>
        <div class="mt-md-2 d-flex justify-content-center">
          <div v-if="boble.voted"><a href="" class="btn-text text-primary link-primary"
              @click="setVote($event, null)">Revote</a></div>
        </div>
      </div>
      <div class="mt-2 mt-md-3 d-flex justify-content-center">
        <button class="btn btn-primary btn-text w-100  btn-coin-info" @click="showModal()">INFO</button>
      </div>
    </div>
  </div>
</template>

<script>
  import * as io from 'socket.io-client'

  let socket = io.connect('https://boblles.com');
  export default {
    name: 'Boble',
    props: {
      boble: Object,
    },
    created: function () {
      socket.on(`addVote_${this.boble.symbol}`, async (info) => {
        let boble = this.boble;
        boble.upVotes = info.upVotes;
        boble.downVotes = info.downVotes;
        boble.percent = info.percent;
        this.$forceUpdate();
      });

      socket.on(`clickVote_${this.boble.symbol}`, async (info) => {
        let boble = this.boble;
        boble.voted = true;
        boble.lastDir = boble.dir;
        boble.dir = info.dir;
      });

      socket.on(`removeVote_${this.boble.symbol}`, async (info) => {
        let boble = this.boble;
        boble.upVotes = info.upVotes;
        boble.downVotes = info.downVotes;
        boble.percent = info.percent;
        this.$forceUpdate();
      });

      socket.on(`clickRevote_${this.boble.symbol}`, async () => {
        let boble = this.boble;
        boble.voted = false;
        boble.lastDir = boble.dir;
        boble.dir = null;
      });
    },
    methods: {
      setVote(event, dir) {
        if (event) event.preventDefault();
        let boble = this.boble;
        boble.lastDir = boble.dir;
        boble.dir = dir;
        boble.voted = (boble.voted == false);
        if (dir == 'up') boble.upVotes += 1;
        else if (dir == 'down') boble.downVotes += 1;
        else if (boble.lastDir == 'up') boble.upVotes -= 1;
        else if (boble.lastDir == 'down') boble.downVotes -= 1;
        boble.percent = (boble.upVotes / Math.max(1, boble.upVotes + boble.downVotes) * 100).toFixed(0);
        this.$forceUpdate();

        if (dir) socket.emit('addVote', { symbol: boble.symbol, dir });
        else if (!dir) socket.emit('removeVote', { symbol: boble.symbol, dir });
      },
      setActive() {
        this.boble.active = (this.boble.active == false);
      },
      showModal() {
        this.$emit('modal', this.boble);
        this.$parent.$refs.modal.open();
      }
    }
  }
</script>

<style>
  :root {
    --dark-green-color: #029502;
    --dark-orange-color: #f07c3e;
    --prefer-ball-size: calc(100vw / 3 - 60px);
    /* 60px is 2 * 30px: the Gutters padding of BS*/
    --max-ball-size: 250px;
    --min-ball-size: 100px;
    --ball-border: 4px;
    --max-ball-fontsize: 92px;

    --prefer-inner-ball: calc(var(--prefer-ball-size) - var(--ball-border) * 2);
    --max-inner-ball: calc(var(--max-ball-size) - var(--ball-border) * 2);
    --min-inner-ball: calc(var(--min-ball-size) - var(--ball-border) * 2);
  }

  @media (max-width: 768px) {
    :root {
      --ball-border: 2px;
    }
  }
</style>

<style scoped>
  @media (max-width: 2560px) {
    .btn-vote {
      width: calc(clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) * 0.65) !important;
    }

    .btn-coin-info {
      width: calc(clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) * 0.65) !important;
    }
  }

  @media (max-width: 992px) {
    .btn-text {
      font-size: 14px !important;
    }

    h1 {
      font-size: 25px !important;
    }

  }

  @media (max-width: 768px) {
    .btn-text {
      font-size: 9px !important;
    }

    .btn {
      padding: 5px 8px !important;
    }

    h1 {
      font-size: 20px !important;
    }

    .btn-vote {
      width: calc(clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) * 0.5) !important;
    }

    .btn-coin-info {
      width: calc(clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) * 0.5) !important;
    }
  }

  @media (max-width: 425px) {
    .btn-vote {
      width: calc(clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) * 0.85) !important;
    }

    .btn-coin-info {
      width: calc(clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) * 0.85) !important;
    }
  }

  .box__ball .progress {
    width: clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) !important;
    height: clamp(var(--min-ball-size), var(--prefer-ball-size), var(--max-ball-size)) !important;
  }

  .box__ball .progress .inner {
    width: clamp(var(--min-inner-ball), var(--prefer-inner-ball), var(--max-inner-ball)) !important;
    height: clamp(var(--min-inner-ball), var(--prefer-inner-ball), var(--max-inner-ball)) !important;
  }

  .box__ball .progress .inner .percent {
    line-height: clamp(var(--min-inner-ball), var(--prefer-inner-ball), var(--max-inner-ball)) !important;
    /* value 0.4 is round of max-fontsize / max-ball-size eg: 92px / 240px, but since calc in css not allow to divide a px unit, this is a workaround */
    font-size: clamp(calc(var(--min-inner-ball) * 0.35), calc(var(--prefer-inner-ball) * 0.35), var(--max-ball-fontsize)) !important;
  }

  .btn-text {
    color: white;
    font-weight: 700;
    font-size: 17px;
  }

  .btnUp {
    background-color: #029502;
  }

  .btnUp:hover {
    background-color: rgb(47, 170, 47);
  }

  .btnDown {
    background-color: #f07c3e;
  }

  .btnDown:hover {
    background-color: #f09463;
  }

  .percent {
    background-color: #1a1a1a;
  }

  .green {
    margin-top: 15px;
  }

  .green .progress,
  .red .progress,
  .orange .progress {
    width: 250px;
    height: 250px;
    position: relative;
    border-radius: 50%;
  }

  .green .progress {
    border: var(--ball-border);
    border-style: solid;
    border-color: #53fc53;
    box-shadow: 0 0 20px #029502;
  }

  .progress:hover {
    border: 5px solid #fff !important;
    box-shadow: 0 0 20px #fff !important;
  }

  .progress:hover .inner .water {
    box-shadow: 0 0 20px #fff !important;
    background: rgb(255, 255, 255, 0.5) !important;
  }

  .progress:hover .inner .percent {
    color: #fff !important;
  }

  .green .progress,
  .red .progress,
  .orange .progress {
    transition: all 1s ease;
  }

  .green .progress .inner,
  .red .progress .inner,
  .orange .progress .inner {
    width: 240px;
    height: 240px;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    border: var(--ball-border) solid #1a1a1a;
    border-radius: 50%;
    transition: all 1s ease;
  }

  .green .progress .inner .water,
  .red .progress .inner .water,
  .orange .progress .inner .water {
    position: absolute;
    z-index: 1;
    width: 200%;
    height: 200%;
    left: -50%;
    border-radius: 40%;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: spin;
    animation-name: spin;
  }

  .green .progress .inner .water {
    top: 25%;
  }

  .green .progress .inner .water {
    background: rgba(83, 252, 83, 0.5);
  }

  .green .progress .inner .water,
  .red .progress .inner .water,
  .orange .progress .inner .water {
    transition: all 1s ease;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
  }

  .green .progress .inner .water {
    box-shadow: 0 0 20px #03bc03;
  }

  .green .progress .inner .glare,
  .red .progress .inner .glare,
  .orange .progress .inner .glare {
    position: absolute;
    top: -120%;
    left: -120%;
    z-index: 5;
    width: 200%;
    height: 200%;
    transform: rotate(45deg);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
    transition: all 1s ease;
  }

  .green .progress .inner .percent,
  .red .progress .inner .percent,
  .orange .progress .inner .percent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: bold;
    text-align: center;
    line-height: 240px;
    font-size: 92.3076923076923px;
    transition: all 1s ease;
  }

  .green .progress .inner .percent {
    color: #03c603;
  }

  .green .progress .inner .percent {
    text-shadow: 0 0 10px #029502;
  }

  .red {
    margin-top: 15px;
  }

  .red .progress {
    border: var(--ball-border);
    border-style: solid;
    border-color: #ed3b3b;
  }

  .red .progress {
    box-shadow: 0 0 20px #7a0b0b;
  }

  .red .progress .inner .water {
    top: 75%;
    background: rgba(237, 59, 59, 0.5);
    box-shadow: 0 0 20px #9b0e0e;
  }

  .red .progress .inner .percent {
    color: #a30f0f;
    text-shadow: 0 0 10px #7a0b0b;
  }

  .orange {
    margin-top: 15px;
  }

  .orange .progress {
    border: var(--ball-border);
    border-style: solid;
    border-color: #f07c3e;
    box-shadow: 0 0 20px #7e320a;
  }

  .orange .progress .inner .water {
    top: 50%;
    background: rgba(240, 124, 62, 0.5);
    box-shadow: 0 0 20px #a0400c;
  }

  .orange .progress .inner .percent {
    color: #a8430d;
    text-shadow: 0 0 10px #7e320a;
  }

  @-webkit-keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>