<template>
    <div class="container-fluid d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center footer">
        <div class="col-12 col-lg-6 d-flex justify-content-center">
            <p class="copyright">© Copyright 2022 BOBLLES - All Rights Reserved</p>
        </div>
        <div class="col-6 d-flex justify-content-center my-4 m-lg-0 footer-icons">
            <a href="https://t.me/crypto_bobles">
                <i class="fa-solid fa-brands fa-paper-plane"></i>
            </a>
            <a href="https://instagram.com/boblles.official/">
                <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@cryptobobles">
                <i class="fa-brands fa-tiktok"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
    @media (max-width: 992px) {
        .footer {
            margin-top: 20px !important;
        }
        .footer-icons {
            width: 45% !important;
            display: flex !important;
            justify-content: space-around !important;
            margin-bottom: 60px !important;
        }
    }

    @media (max-width: 768px) {
        .copyright {
            font-size: 12px !important;
        }
    }

    .footer {
        min-height: 70px; 
        margin-top: 50px;
    }

    .copyright {
        font-size: 18px; 
        font-weight: 800; 
        margin: 0;
    }

    .fa-brands {
        font-size: 40px;
        color: white;
        margin-right: 30px;
    }

    .fa-brands:hover {
        color: gray;
    }
</style>
