<template>
    <div>
        <h1 v-if="name" class="text-center">{{name}} Info</h1>
        <h1 v-else class="text-center">Info currently unavailable</h1>
        <div v-if="name" class="text-start">
            <h4 class="fw-bolder">Name: <span class="fw-light">{{name}}</span></h4>
            <h4 class="fw-bolder">Symbol: <span class="fw-light">{{symbol}}</span></h4>
            <div v-if="price">
              <h4 class="fw-bolder">Price: <span class="fw-light">{{price+'$'}}</span></h4>
              <h4 class="fw-bolder">MarketCap: <span class="fw-light">{{mc+'$'}}</span></h4>
              <h4 class="fw-bolder">Volume 24h: <span class="fw-light">{{volume+'$'}}</span></h4>
              <h4 class="fw-bolder">Price Change 24h: <span class="fw-light">{{priceChange}}</span></h4>
            </div>
            <h4 class="fw-bolder">Description: <span class="fw-light" v-html="description"></span></h4>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    name: String,
    symbol: String,
    description: String,
    price: String,
    mc: String,
    volume: String,
    priceChange: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
