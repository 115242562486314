<template>
    <div class="how-it-works">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="main col-md">
                    <h1 class="display-4">How it works?</h1>
                    <p>The cryptocurrency market today is the most decentralized. In this regard, brokers, market makers, etc. have minimal impact on the market price. The method of trading "Crowd Psychology" is most effective at this market. Knowing the preferences and plans of the crowd, you can easily always stay in the trend.
        
                        Most people's choices drive the price. Share this service to determine the trend as accurately as possible.
                        
                        P.S. Skeptics can trade backwards 😄</p>
                </div>
                <div class="col-md d-flex flex-column d-md-block">
                    <div>
                        <p class="steps">Step 1</p>
                        <p>If you have predictions about any coins - select the <span style="color: #029502">Up</span> or <span style="color: #f07c3e">Down</span> button to help the project and other traders.</p>
                    </div>
                    <div>
                        <p class="steps">Step 2</p>
                        <p>Based on the votes, choose promising coins. The <span style="color: #029502">greenest bubbles</span> are recommended to buy and the <span style="color: #f07c3e">reddest bubbles</span> to sell.</p>
                    </div>
                    <div>
                        <p class="steps">Step 3</p>
                        <p>To find out more information about the coin you can click the <span style="color: #0d6efd">Info</span> button</p>
                    </div>
                    <div>
                        <p class="steps">Step 4</p>
                        <p>Click on the bubble of your choice to buy the coin.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HowItWorks',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .steps {
        font-size: 20px;
        font-weight: 900;
    }

    .main p {
        letter-spacing: 1px;
    }   
</style>
